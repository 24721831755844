import { useMatches } from "@remix-run/react";

export function useAppTenantRoute() {
  const paths: string[] = ["routes/app.$tenant"];
  return useMatches().find((f) => paths.includes(f.id.toLowerCase()));
}
export function useAdminRoute() {
  const paths: string[] = ["routes/admin"];
  return useMatches().find((f) => paths.includes(f.id.toLowerCase()));
}

export function useGuestRoute() {
  const paths: string[] = ["routes/recipe"];
  return useMatches().find((f) => paths.includes(f.id.toLowerCase()));
}

export function useOnFrontend() {
  const appTenantRoute = useAppTenantRoute();
  const adminRoute = useAdminRoute();
  return !appTenantRoute && !adminRoute;
}

export function useOnSearch() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return !!matches?.find((match) => match.id === "routes/app.$tenant/dashboard/search");
  } else {
    return !!matches?.find((match) => match.id === "routes/recipe/search");
  }
}

export function useSearchRoute() {
  const appTenantRoute = useAppTenantRoute();
  if (appTenantRoute) {
    return `${appTenantRoute?.pathname}/dashboard/search`;
  } else {
    return `/recipe/search`;
  }
}

export function useOnHomepage() {
  const matches = useMatches();
  return !!matches?.find((match) => match.id === "routes/index");
}

export function useOnSplash() {
  const matches = useMatches();
  return !!matches?.find((match) => match.id === "routes/splash");
}

export function useOnRecipe() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return matches?.find((match) => match.id === "routes/app.$tenant/recipe/$urlId.$urlKey");
  } else {
    return matches?.find((match) => match.id === "routes/recipe/$urlId.$urlKey");
  }
}

export function useOnRecipeList() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return matches?.find((match) => match.id === "routes/app.$tenant/recipe/index");
  }
}

export function useOnProfile() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return matches?.find((match) => match.id === "routes/app.$tenant/chef.$displayName");
  }
  return false;
}

export function useOnTraining() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return matches?.find((match) => match.id === "routes/app.$tenant/train");
  }
}

export function useOnCollections() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return matches?.find((match) => match.id === "routes/app.$tenant/collection.$urlId.$urlKey");
  } else {
    return matches?.find((match) => match.id === "routes/collection.$urlId.$urlKey");
  }
}

export function useOnShoppingList() {
  const appTenantRoute = useAppTenantRoute();
  const matches = useMatches();
  if (appTenantRoute) {
    return matches?.find((match) => match.id === "routes/app.$tenant/shop");
  } else {
    return matches?.find((match) => match.id === "routes/recipe/shop");
  }
}

export function useCurrentRecipe() {
  const onRecipe = useOnRecipe();
  return onRecipe?.data?.item || {};
}

export function useCurrentCollection() {
  const onCollection = useOnCollections();
  return onCollection?.data?.collection || {};
}
